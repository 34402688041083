export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Agenda',
    route: 'calendar',
    icon: 'CalendarIcon',
  },
  {
    title: 'Klanten',
    route: 'customers',
    icon: 'UsersIcon',
  },
  {
    title: 'Kassa',
    route: 'register',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Producten',
    route: 'home',
    icon: 'TagIcon',
  },
  {
    title: 'Diensten',
    route: 'home',
    icon: 'BoxIcon',
  },
  {
    title: 'Berichten',
    route: 'home',
    icon: 'MailIcon',
  },
  {
    title: 'Feedback',
    route: 'home',
    icon: 'MessageCircleIcon',
  },
  {
    title: 'Rapporten',
    route: 'reports',
    icon: 'PieChartIcon',
  },
]
